html {
  scroll-behavior: smooth;
}

.text-shadow {
  text-shadow: 0 0.25rem 0.75rem #2f4f4f;
}

.shadow-hover:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.shadow-inset {
  box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.25);
}

button,
button:focus {
  outline: none;
}

/*.btn-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}*/

.btn-unstyled {
  border: none;
  background: inherit;
  padding: 0;
  margin: 0;
}

/*.modal-footer {
  padding: 0.75rem 0;
  justify-content: space-evenly;
}

.modal-footer > div:first-child {
  width: 100%;
}*/

/* web colors */
.bg-pink {
  background: pink !important;
}
.bg-hotpink {
  background: hotpink !important;
}
.bg-deeppink {
  background: deeppink !important;
}
.bg-springgreen {
  background: springgreen !important;
}
.bg-mediumspringgreen {
  background: mediumspringgreen !important;
}
.bg-darkviolet {
  background: darkviolet !important;
}

/* extend animate.css */
.animate__animated.animate__delay-250ms {
  animation-delay: 250ms;
}
.animate__animated.animate__delay-500ms {
  animation-delay: 500ms;
}
.animate__animated.animate__delay-750ms {
  animation-delay: 750ms;
}
.animate__animated.animate__delay-1250ms {
  animation-delay: 1250ms;
}
.animate__animated.animate__delay-1500ms {
  animation-delay: 1500ms;
}
.animate__animated.animate__delay-1750ms {
  animation-delay: 1750ms;
}

/* img container to maintain certain aspect ratio 3:2 4:3 16:9 */
.aspect-ratio > img,
.aspect-ratio > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aspect-ratio {
  position: relative;
}
.aspect-ratio::before {
  content: '';
  display: block;
}
.aspect-ratio-3x2::before {
  padding-bottom: calc(100% / (3 / 2));
}
.aspect-ratio-4x3::before {
  padding-bottom: calc(100% / (4 / 3));
}
.aspect-ratio-16x9::before {
  padding-bottom: calc(100% / (16 / 9));
}

/* potrait */
.aspect-ratio-1x2::before {
  padding-bottom: calc(100% / (1 / 2));
}
.aspect-ratio-1x3::before {
  padding-bottom: calc(100% / (1 / 3));
}
.aspect-ratio-1x4::before {
  padding-bottom: calc(100% / (1 / 4));
}
.aspect-ratio-2x3::before {
  padding-bottom: calc(100% / (2 / 3));
}
.aspect-ratio-3x4::before {
  padding-bottom: calc(100% / (3 / 4));
}
